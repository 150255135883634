<template lang="html">
  <full-page>
    <div slot="sections">
      <section class="fullpage-center" ref="sections">
        <SlideShow ref="slider" :categories="categories"/>
        <category-menu @hovered = "ho" :categories="categories"></category-menu>
      </section>
      <section class="fullpage-right" ref="sections">
        <modir-amel></modir-amel>
      </section>
      <section class="fullpage-center" ref="sections">
        <about border="border-main">
          <p class="text" slot="text">لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد</p>
        </about>
      </section>
      <section class="fullpage-center" ref="sections">
        <Footer />
      </section>
    </div>
  </full-page>
</template>

<script>
import FullPage from '@/components/FullPage';
import SlideShow from '@/components/SlideShow';
import CategoryMenu from '@/components/CategoryMenu';
import ModirAmel from '@/components/ModirAmel';
import About from '@/components/About';
import Footer from '../components/Footer2.vue'
import httpClient from '@/services/http.service'
export default {
  name:'Ho',
  components: {
    FullPage,
    SlideShow,
    CategoryMenu,
    ModirAmel,
    About,
    Footer
  },
  data(){
    return {
      categories : new Array(),
    };
  },
  methods: {
    async ho(value) {
       //setInterval(this.$refs.slider.indexing(value), 5000);
      //this.$refs.slider.indexing(value);
      await setTimeout(this.how(value), 2000);
    },
    async how(val){
      await this.$refs.slider.indexing(val);
    },
    async bindData(){
      const {status,data} = await httpClient.get('fa/categories');
      if (status == 200) {
        this.categories = data;
      }else{
        alert('not connect to api');
      }
    }
  },
  async created() {
    await this.bindData();
    await this.$refs.slider.dataSync();
    console.log(this.categories);
  },
}
</script>

<style lang="css" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
  transform: translateY(40px);
  opacity: 0;
}
</style>
