<template>
  <div class="container pt-5 mt-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6">
        <div class="scroll">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'ScrollDown'
}
</script>

<style lang="css" scoped>
.scroll {
position: relative;
width: 24px;
height: 24px;
font-family: 'Yekan';
}

.chevron {
position: absolute;
width: 28px;
height: 8px;
right: 150px;
opacity: 0;
transform: scale3d(0.5, 0.5, 0.5);
animation: move 3s ease-out infinite;
}

.chevron:first-child {
animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
content: ' ';
position: absolute;
top: 0;
height: 100%;
width: 51%;
background: #070144e6;
}

.chevron:before {
left: 0;
transform: skew(0deg, 30deg);
}

.chevron:after {
right: 0;
width: 50%;
transform: skew(0deg, -30deg);
}

@keyframes move {
25% {
  opacity: 1;

}
33% {
  opacity: 1;
  transform: translateY(30px);
}
67% {
  opacity: 1;
  transform: translateY(40px);
}
100% {
  opacity: 0;
  transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
}
}

.text {
display: block;
margin-top: 75px;
font-family: 'Yekan';
font-size: 20px;
color: #fff;
text-transform: uppercase;
white-space: nowrap;
opacity: .25;
animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
to {
  opacity: 1;
}
}
</style>
