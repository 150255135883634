<template lang="html">
  <div class="menu justify-content-center align-items-center">
    <div class="col-4 bordered menu-item h-100" v-for="category in categories" :key="category.id">
      <div class="thumbnail">
        <img class="thumbnail-img" :src="'/img/'+category.sliderImg" alt="">
      </div>
        <a :href="'/'+$i18n.locale+'/'+category.title">
          <img :src="'/img/'+category.logo" alt="azarpajooheshTajhiz" @mouseover= "ho(category.id,category.data.name)" @mouseleave = "ho(0,'گروه صنعتی آذر پژوهش')">
        </a>
    </div>
    <div class="slogon">
      <transition name="slide-fade" mode="out-in">
        <h1 :key="txt" class="slogon-text">
          {{ txt }}
        </h1>
      </transition>
      <scroll-down></scroll-down>

    </div>
  </div>
</template>

<script>
import ScrollDown from './ScrollDown';
export default {
  name:'CategoryMenu',
  props:
    {
      categories:Array,
    },
  data(){
    return{
      index:0,
      txt:'گروه صنعتی آذر پژوهش'
    }
  },
  methods: {
    howo(){
      return this.$emit('hovered',this.index);
    },
    ho(ind,tx){
      this.index = ind;
      console.log(ind);
      this.txt = tx;
      setTimeout(() => {  this.howo(); }, 500);
    },
  },
  components: {
    ScrollDown,
  }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
.bordered {
  border: 1px solid hsla(0, 0%, 72.5%, 0.52);
  border-top: none;
}
.container{
  width: 100% !important;
}
.menu{
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  display: block;
  right: 12.5%;
  width: 100%;
  height: 100%;
  z-index: 200;
}
.menu-item{
  display: inline-block;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}
.menu-item img{
  width: 80%;
  position: relative;
  top: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  filter: grayscale(30%);
  border-top: 1px solid hsla(0, 0%, 72.5%, 0.52);
}
.menu-item img:hover{
  transition: all 0.3s ease;
  filter: grayscale(0%);
  filter: contrast(150%)
}
.slogon{
    position: absolute;
    top: 30%;
    left: 37.5%;
    right: auto;
    width: 50%;
    text-align: center;
}
.slogon-text{
  text-align: center;
  font-weight: bolder;
  color: #070144e6;
  font-family: 'Fredoka One','Vazir', sans-serif;
  font-size: 60px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
  transform: translateY(40px);
  opacity: 0;
}
.thumbnail{
  width: 100%;
  height: 100%;
  position: absolute;
  top: -100%;
  left: -150px;
}
.thumbnail-img{
  width: 100%;
  height: 100%;
}
</style>
